<template>
  <div class="ElectronicContract">
    <BannerVideo :video-url="$StaticFileUrl + '/mp/video/Electronic_contract_banner_2.mp4'">
      <div class="title wow fadeInDown" data-wow-duration="1s">为企业提供安全合规的电子签</div>
      <div class="desc wow fadeInDown" data-wow-duration="1s">提供电子合同签约全生命周期管理</div>
      <div class="flex-row flex-col-center">
        <div class="btn-red wow flipInX" data-wow-duration="1s" style="margin-top: 100px">立即体验</div>
      </div>
    </BannerVideo>
  </div>
</template>

<script>
import WOW from 'wowjs'
import BannerVideo from '@/components/BannerVideo'

/**
 * @author  XuHongli
 * @date  2022/12/28 10:12
 * @version 1.0
 * @description
 */
export default {
  name: 'ElectronicContract',
  components: {BannerVideo},
  mounted() {
    new WOW.WOW().init()
  }
}
</script>

<style scoped>

</style>
